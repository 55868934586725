import React from 'react'
import cx from 'classnames'
import Img from 'next/image'
import { useNextSanityImage } from 'next-sanity-image'
import { getSanityClient } from '../lib/sanity'

const DividerPhoto = ({ data = {} }) => {
  const { photo, showFullsize } = data

  if (!photo) return null
  const imageProps = useNextSanityImage(getSanityClient(), photo)
  return (
    <section className={cx('divider', { 'full-size': showFullsize })}>
      {showFullsize ? (
        <Img {...imageProps} layout="responsive" objectFit="contain" />
      ) : (
        <Img {...imageProps} layout="fill" objectFit="cover" />
      )}
    </section>
  )
}

export default DividerPhoto
